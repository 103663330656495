// LIBRARIES
@import "~@ng-select/ng-select/themes/default.theme.css";

// CUSTOM GLOBAL
@import "scss/variables";
@import "scss/alerts";
@import "scss/buttons";
@import "scss/file-upload";
@import "scss/forms";
@import "scss/tables";
@import "scss/global";
@import "scss/modal";
@import "scss/typography";
@import "scss/ng-select";
@import "scss/whitelabeling";

// OVERLOADS
@import "scss/accordions";
@import "scss/primeng";
@import "scss/3rd-party";
@import "scss/forms/validations";
