@import "variables";

:root {
    --bs-primary-rgb: var(--primaryColorRGB);
}

// .text-primary {
//     color: $primary-color !important;
// }

.btn {
    &:disabled, .disabled {
        --bs-btn-disabled-bg: var(--primaryColor);
        --bs-btn-disabled-border-color: color-mix(in srgb, var(--primaryColor), #fff 25%);
    }
}

.btn-primary {
    --bs-btn-bg: #{ $primary-color };
    --bs-btn-border-color: #{ $primary-color-75 };
    --bs-btn-hover-bg: color-mix(in srgb, var(--primaryColor), #000 25%);
    --bs-btn-hover-border-color: color-mix(in srgb, var(--primaryColor), #000 25%);
    --bs-btn-active-bg: var(--bs-btn-hover-bg);
    --bs-btn-active-border-color: var(--bs-btn-hover-border-color);
}
