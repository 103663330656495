.accordion-flush {

    .accordion-item {
        background-color: transparent;

        .accordion-button {
            padding: 8px 0px;
            margin: 2px 0px;
            background-color: transparent;
            text-align: left;
            display: flex;
            align-items: center;
            padding-left: 22px;
            box-shadow: none;
            color: black;

            &::after {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
                background-size: 16px;
            }
        }
    }
}
