/* -------------------------------------
               File Upload
----------------------------------------*/

.uploader {
    display: block;
    clear: both;
    margin: 0 auto;
    width: 100%;

    .py-3 img {
        width: 130px !important;
    }

    label {
        width: 100%;
        padding: 2rem 1.5rem;
        text-align: center;
        background: #fff;
        border-radius: 7px;
        border: 3px dashed $dark-gray-10;
        transition: all .2s ease;
        user-select: none;

        &:hover {
            border-color: $primary-color;
        }

        &.hover {
            border: 3px solid $primary-color;
            box-shadow: inset 0 0 0 6px #eee;
        }
    }

    input[type="file"] {
        display: none;
    }

}