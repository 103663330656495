/**
* THIS FILE IS DEPRECATED DO NOT ADD ANY STYLES HERE
*
*
**/

/*-------------------------------------
            Global NG Select
--------------------------------------*/
.multi-filter-select-wrapper {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 20px;
}

.filter-select-wrapper {
    ng-select[appearance="outline"] {
        &.ng-select {
            &.ng-select-single .ng-select-container {
                &:hover {
                    box-shadow: none;
                }
            }

            .ng-select-container {
                .ng-value-container {
                    .ng-value .ng-value-label {
                        font-size: $small-font-size;
                    }
                }
            }
        }
    }
}

ng-select[appearance="outline"] {
    &.ng-select {
        .ng-select-container {
            border-color: $border-color;

            &:hover {
                box-shadow: none;
            }
        }

        &.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
            border-color: $light-blue;
            box-shadow: none;
        }

        .ng-select-container {
            &:focus {
                border-color: $light-blue;
            }

            .ng-value-container {
                .ng-value .ng-value-label {
                    font-size: $base-font-size;
                }
            }
        }
    }

    ng-dropdown-panel {
        &.ng-dropdown-panel {
            border-radius: 0 0 4px 4px;

            .ng-dropdown-panel-items {
                font-size: $base-font-size;

                .ng-option {
                    &.ng-option-selected {
                        background-color: $light-blue;
                        color: white;
                    }
                }
            }
        }
    }
}

/*-------------------------------------
               Ng Select Tags
  --------------------------------------*/
.tags-wrapper {
    .ng-select-container {
        padding-top: 2px;
        padding-bottom: 1px;
    }

    .ng-input>input {
        padding-top: 0px;
    }

    .ng-value {
        line-height: 20px;
        padding: 3px 8px !important;
        background-color: $dark-gray-10 !important;
        border-radius: 30px !important;
    }

    .ng-value-label {
        white-space: normal !important;
    }

    .ng-value-label,
    .ng-value-icon {
        background-color: $dark-gray-10 !important;
        color: $dark-gray-75 !important;
        font-size: 12px;
    }

    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
        display: flex;
        align-items: center;
    }

    &.close-none {
        .ng-value-icon.left {
            display: none !important;
        }
    }
}

/*-------------------------------------
            Filter Select Sorting
    --------------------------------------*/

.filter-select-wrapper {
    position: relative;

    ng-select {
        direction: rtl;
    }

    .bi {
        font-size: 16px;
        color: $dark-gray-75;
        margin-right: 4px;
        margin-top: -2px !important;
    }

    .ng-select-container {
        background-color: transparent !important;
        border: none !important;
        color: $dark-gray-75;
        font-size: 12px;
        cursor: pointer !important;
        position: absolute !important;
        right: 0px;
        top: -8px;
        z-index: 10;
        padding-right: 30px;
        width: fit-content !important;
    }

    &.v2 {
        position: static;

        .ng-select-container {
            width: 0px !important;

            .ng-value {
                display: none;
            }
        }
    }

    .ng-dropdown-panel {
        margin-top: 26px;
        border-radius: 10px !important;
        width: 190px !important;
        right: 0px;
        padding: 10px 0px !important;
        border: none !important;
        -webkit-box-shadow: 0px 7px 19px -6px rgba(0, 0, 0, 0.25) !important;
        -moz-box-shadow: 0px 7px 19px -6px rgba(0, 0, 0, 0.25) !important;
        box-shadow: 0px 7px 19px -6px rgba(0, 0, 0, 0.25) !important;

        i-feather {
            margin-right: 10px;
        }
    }

    .ng-option {
        font-size: 12px !important;
        text-align: left;
        padding-left: 20px !important;
        color: $dark-gray-75 !important;
        font-weight: 400 !important;
    }

    .ng-option-selected {
        background-color: $light-blue !important;
        color: white !important;

        .bi {
            color: white !important;
        }
    }

    .ng-arrow-wrapper {
        display: none;
    }

    &.lg-width {
        .ng-dropdown-panel {
            width: fit-content !important;
        }
    }

    .bi-filter,
    .bi-tag {
        font-size: 18px;
        line-height: 16px;
        color: $dark-gray-75;
        position: absolute;
        right: -4px;
        top: 4px;
    }
}

.filter-select-wrapper-ltr {
    position: relative;

    .bi {
        font-size: 16px;
        color: $dark-gray-75;
        margin-left: 4px;
        margin-top: -2px !important;
    }

    .ng-select-container {
        background-color: transparent !important;
        border: none !important;
        color: $dark-gray-75;
        font-size: 12px;
        cursor: pointer !important;
        position: absolute !important;
        left: 0px;
        top: -10px;
        z-index: 10;
        padding-left: 20px;
        width: fit-content !important;
    }

    .ng-dropdown-panel {
        margin-top: 26px;
        border-radius: 10px !important;
        width: 190px !important;
        left: 0px;
        padding: 10px 0px !important;
        border: none !important;
        -webkit-box-shadow: 0px 7px 19px -6px rgba(0, 0, 0, 0.25) !important;
        -moz-box-shadow: 0px 7px 19px -6px rgba(0, 0, 0, 0.25) !important;
        box-shadow: 0px 7px 19px -6px rgba(0, 0, 0, 0.25) !important;

        i-feather {
            margin-left: 10px;
        }
    }

    &.ng-dropdown-panel-w-lg {
        .ng-dropdown-panel {
            width: 350px !important;
        }
    }

    .ng-option {
        font-size: 12px !important;
        text-align: left;
        padding-right: 20px !important;
        color: $dark-gray-75 !important;
        font-weight: 400 !important;
    }

    .ng-option-selected {
        background-color: $light-blue !important;
        color: white !important;

        .bi {
            color: white !important;
        }
    }

    .ng-arrow-wrapper {
        display: none;
    }

    &.lg-width {
        .ng-dropdown-panel {
            width: fit-content !important;
        }
    }

    .ng-select .ng-select-container .ng-value-container .ng-input input {
        margin-left: 20px;
    }

    .bi-filter,
    .bi-tag {
        font-size: 18px;
        line-height: 16px;
        color: $dark-gray-75;
        position: absolute;
        left: -4px;
        top: 4px;
    }
}

.multi-filter-select-wrapper {
    .filter-select-wrapper {
        .ng-select-container {
            position: relative !important;
        }

        .ng-dropdown-panel {
            margin-top: -22px;
        }
    }
}