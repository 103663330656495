
.alert {
    display: flex;
    align-items: center;
    justify-items: center;
    font-size: 12px;
    background-color: transparent;
    padding: 10px 15px;

    .bi {
        font-size: 18px;
        margin-right: 10px;
    }

    strong {
        font-size: 12px;
    }

    &.alert-danger {
        border-left: 4px solid $primary-color !important;
        color: $primary-color;
        border-color: $primary-color-25;
        background-color: $primary-color-5;
    }

    &.alert-success {
        border-left: 4px solid $light-green !important;
        color: $light-green;
        border-color: $light-green-25;
        background-color: $light-green-5;
    }

    &.alert-warning {
        border-left: 4px solid $orange !important;
        color: $orange;
        border-color: $orange-25;
        background-color: $orange-5;
    }

    &.alert-info {
        border-left: 4px solid $light-blue !important;
        color: $light-blue;
        border-color: $light-blue-25;
        background-color: $light-blue-5;
    }
}