@import "variables";

small.validation-error {
    color: $danger-color;
}

.form-control {
    &.ng-dirty.ng-invalid {
        border-color: $danger-color;
    }
}
