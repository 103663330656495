@import "shadows";
@import "levels";

ng-select[appearance="slim"] {
    &.ng-select {
        &.ng-select-single .ng-select-container {
            height: 25px;

            &:hover {
                box-shadow: none;
            }

            .ng-value-container .ng-input {
                top: 50%;
                transform: translate(0, -50%);
            }
        }

        .ng-select-container {
            background-color: transparent;
            border: none;
            cursor: pointer;
            z-index: map-get($z-index-levels, above);
            color: $dark-gray-75;

            .ng-value-container {
                .ng-value {
                    color: inherit;

                    .ng-value-label {
                        font-size: $small-font-size;
                    }
                }
                .ng-input > input {
                    color: $dark-gray;
                    font-weight: 400;
                }
            }

            .ng-arrow-wrapper {
                display: none;
            }
        }
    }

    ng-dropdown-panel {
        &.ng-dropdown-panel {
            border-radius: 8px;
            border: none;
            padding: 0.5rem 0;
            box-shadow: map-get($shadows, base);

            .ng-dropdown-panel-items {
                font-size: $small-font-size;

                .ng-option {
                    &.ng-option-selected {
                        background-color: $light-blue;
                        color: white;
                    }
                }
            }
        }
    }
}
