// Layout
$text-color: #1e2125;

// Theme colors
$primary-color: var(--primaryColor);
$positive-color: #23b559;
$danger-color: #eb1d24;
$disabled-color: #b9b3b3;

// $primary-color-75: #f0565b;
$primary-color-75: color-mix(in srgb, var(--primaryColor),#fff 25%);
// $primary-color-50: #f58e91;
$primary-color-50: color-mix(in srgb, var(--primaryColor),#fff 50%);
// $primary-color-25: #fac6c8;
$primary-color-25: color-mix(in srgb, var(--primaryColor),#fff 75%);
// $primary-color-10: #fde8e9;
$primary-color-10: color-mix(in srgb, var(--primaryColor),#fff 90%);
// $primary-color-5: #fef3f4;
$primary-color-5: color-mix(in srgb, var(--primaryColor),#fff 95%);

// Colors
$orange: #f6a623;
$orange-75: #f9bd5a;
$orange-50: #fad291;
$orange-25: #fde9c8;
$orange-10: #fef6e9;
$orange-5: #fefaf4;

$light-blue: #4bbfdd;
$light-blue-75: #78d0e6;
$light-blue-50: #a6dfee;
$light-blue-25: #d3f0f7;
$light-blue-10: #edf8fc;
$light-blue-5: #f6fcfe;

$light-green: #23b559;
$light-green-75: #5bc783;
$light-green-50: #91d9ac;
$light-green-25: #c8ecd5;
$light-green-10: #e9f7ee;
$light-green-5: #f4fbf6;

$dark-gray: #32373f;
$dark-gray-75: #6c6f74;
$dark-gray-50: #a0a1a5;
$dark-gray-25: #d0d1d3;
$dark-gray-10: #ecedee;

$light-gray: #f9f9f9;
$medium-gray: #ececec;
$border-color: #d8d8d8;
$bg-color: #f5f6fa;

$light-overlay: rgba(255, 255, 255, 0.8);

// Theme
// $theme-colors: (
//     "primary": #eb1d24,
//     "secondary": #6c6f74,
//     "success": #23b559,
//     "danger": #eb1d24,
//     "warning": #f6a623,
//     "info": #4bbfdd,
//     "light": #f9f9f9,
//     "dark": #222222,
//     "disabled": #b9b3b3,
// );

$small-font-size: 12px;
$base-font-size: 14px;

@mixin box-shadow($shadow: 0px 2px 9px -2px rgba(0, 0, 0, 0.17)) {
    -webkit-box-shadow: $shadow;
    -moz-box-shadow: $shadow;
    box-shadow: $shadow;
}

@mixin box-shadow2($shadow2: 0px 7px 19px -6px rgba(0, 0, 0, 0.25)) {
    -webkit-box-shadow: $shadow2;
    -moz-box-shadow: $shadow2;
    box-shadow2: $shadow2;
}

@mixin box-shadow3($shadow3: 0px 20px 40px 10px rgba(0, 0, 0, 0.07)) {
    -webkit-box-shadow: $shadow3;
    -moz-box-shadow: $shadow3;
    box-shadow2: $shadow3;
}

@mixin box-shadow-purple($box-shadow-purple: 0px 20px 50px 0px rgb(125, 129, 222, 0.3)) {
    -webkit-box-shadow: $box-shadow-purple;
    -moz-box-shadow: $box-shadow-purple;
    box-shadow-purple: $box-shadow-purple;
}

@mixin transition($transition: all 0.5s) {
    -moz-transition: $transition;
    -o-transition: $transition;
    -webkit-transition: $transition;
    transition: $transition;
}

@mixin transform($transform: scale(1.2)) {
    -ms-transform: $transform;
    -moz-transform: $transform;
    -webkit-transform: $transform;
    -o-transform: $transform;
    transform: $transform;
}

@mixin dark-overlay {
    &:after {
        content: "";
        width: 100%;
        height: auto;
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }
}

@mixin animate-skeleton {
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    z-index: 100;
    border-radius: 10px;
    background-color: $ab-color;
    background-image: -webkit-gradient(linear,
            left center,
            right center,
            from($ab-color),
            color-stop(0.2, #edeef1),
            color-stop(0.4, $ab-color),
            to($ab-color));
    background-image: -webkit-linear-gradient(left,
            $ab-color 0%,
            #edeef1 20%,
            $ab-color 40%,
            $ab-color 100%);
    background-size: 100% 100%;
    position: relative;
}

@mixin btn-options {
    position: absolute;
    right: 0px;
    transition: 0.3s;
    z-index: 100;

    .btn {
        width: 30px;
        height: 30px;
        margin-left: 4px;
        padding: 0px !important;
    }
}
