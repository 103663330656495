/*-------------------------------------
                Inputs
--------------------------------------*/

input.form-control,
textarea.form-control,
.form-select {
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 400;
    color: $dark-gray;
    border-color: $border-color;

    &::placeholder {
        font-weight: 400;
    }

    &.is-valid {
        border-color: $light-green !important;
    }

    &.is-invalid {
        border-color: $primary-color !important;
    }
}

input.form-control-sm {
    font-size: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
}

textarea.form-control {
    padding-top: 10px;
    padding-bottom: 10px;
}

textarea.form-control-sm {
    font-size: 12px;
}


.form-control {
    &::placeholder {
        color: $dark-gray-50;
        font-weight: 300;
    }

    &:focus {
        border-color: $light-blue;
        outline: 0;
        box-shadow: none;
    }

    &:read-only {
        background-color: $dark-gray-10;
        color: $dark-gray-75;
    }
}

.input-group-text {
    color: $dark-gray-75;
    background-color: $dark-gray-10;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 14px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance: textfield;
    /* Firefox */
}

/*-------------------------------------
                Checkbox
--------------------------------------*/

.checkbox {
    --bg: transparent;
    --brdr: #D0D1D3;
    --brdr-actv: var(--primaryColor);
    --brdr-hovr: #{ $primary-color-75 };
    --dur: calc((var(--size, 2)/2) * 0.6s);
    display: inline-block;
    width: calc(var(--size, 1) * 18px);
    position: relative;

    &:after {
        content: "";
        width: 100%;
        padding-top: 100%;
        display: block;
    }

    &>* {
        position: absolute;
    }

    input {
        -webkit-appearance: none;
        -moz-appearance: none;
        -webkit-tap-highlight-color: transparent;
        cursor: pointer;
        background-color: var(--bg);
        border-radius: calc(var(--size, 1) * 4px);
        border: calc(var(--newBrdr, var(--size, 1)) * 1px) solid;
        color: var(--newBrdrClr, var(--brdr));
        outline: none;
        margin: 0;
        padding: 0;
        transition: all calc(var(--dur) / 3) linear;

        &:hover,
        &:checked {
            --newBrdr: calc(var(--size, 1) * 2);
        }

        &:hover {
            --newBrdrClr: var(--brdr-hovr);
        }

        &:checked {
            --newBrdrClr: var(--brdr-actv);
            transition-delay: calc(var(--dur) /1.3);

            &+svg {
                --dashArray: 16 93;
                --dashOffset: 109;
            }
        }
    }

    svg {
        fill: none;
        left: 0;
        pointer-events: none;
        stroke: var(--stroke, var(--border-active));
        stroke-dasharray: var(--dashArray, 93);
        stroke-dashoffset: var(--dashOffset, 94);
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 2px;
        top: 0;
        transition: stroke-dasharray var(--dur), stroke-dashoffset var(--dur);
    }

    svg,
    input {
        display: block;
        height: 100%;
        width: 100%;
    }
}

.checkbox-wrapper {
    padding-left: 30px;
    position: relative;

    .checkbox {
        position: absolute;
        left: 0px;
        top: 4px;
    }

    label {
        line-height: 20px;
        cursor: pointer;
    }
}

.form-error {
    font-size: 12px;
    line-height: 18px;
    color: $primary-color;
}


/*-------------------------------------
             Radio Button
--------------------------------------*/

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;

    &+label {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: $dark-gray-75;
        font-weight: 400;
        font-size: 12px;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 20px;
            height: 20px;
            border: 2px solid #d1d6ee;
            border-radius: 50%;
            background: #fff;
        }

        &:after {
            content: '';
            width: 10px;
            height: 10px;
            background: $primary-color;
            position: absolute;
            top: 5px;
            left: 5px;
            border-radius: 50%;
            transition: all 0.2s ease;
        }
    }
}

[type="radio"]:not(:checked)+label:after {
    opacity: 0;
    transform: scale(0);
}

[type="radio"]:checked+label:after {
    opacity: 1;
    transform: scale(1);
}

/*-------------------------------------
                Toggle
--------------------------------------*/

.toggle-box {
    display: flex;
    flex-direction: row;

    .toggle-caption {
        margin-top: -1px;
        padding-left: 10px;

        p {
            font-weight: 400;
        }
    }
}

.toggleWrapper {
    position: relative;
}

.toggleWrapper input {
    position: absolute;
    left: -99em;
    opacity: 0;
}

/*the visible body*/
.toggle {
    border-radius: 50px;
    cursor: pointer;
    display: inline-block;
    position: relative;
    width: 46px;
    height: 26px;
    padding: 3px !important;
    background: $dark-gray-10;
}

.toggle::before,
.toggle::after {
    position: absolute;
    z-index: 2;
    transition: all 200ms;
}

/*the white box made*/
.toggle_handler {
    display: inline-block;
    position: relative;
    z-index: 1;
    background: $dark-gray-25;
    width: 20px;
    height: 20px;
    border-radius: 50px;
    transition: all 200ms;
}

input:checked+.toggle {
    background: $primary-color-10;
}

input:checked+.toggle .toggle_handler {
    background-color: $primary-color;
    width: 20px;
    transform: translateX(19px);
}
