@import "levels";
/**
* custom appearance usage: <ng-select class="variant-slim">
*/

// DEFAULT VARIANT
ng-select {
    &.ng-select {
        color: $dark-gray-75;

        &.ng-select-single .ng-select-container {
            height: 43px;

            .ng-value-container .ng-input {
                top: 0;
            }
        }

        &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
            border-color: $light-blue;
            box-shadow: none;
        }

        .ng-select-container .ng-value-container {
            .ng-value .ng-value-label {
                font-size: $base-font-size;
            }
            .ng-input > input {
                color: $dark-gray;
                padding-top: 10px;
                padding-bottom: 10px;
                font-weight: 400;
                border-color: $border-color;
            }
        }
    }
}
