/*-------------------------------------
            Bootstrap Modal
--------------------------------------*/

.modal-content {
    border-radius: 10px;
    border: 0px;
    @include box-shadow2;
}

.modal {
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-backdrop.show {
    display: none;
}

.modal-header {
    border-bottom: none;
    padding: 24px 30px;
    padding-bottom: 20px;
    background-color: white;
    color: $dark-gray;
    border-radius: 10px 10px 0 0;
    z-index: 10 !important;
}

.modal-body {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-left: 30px !important;
    padding-right: 30px !important;

    &.withoutPadding {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    h5 {
        line-height: 30px;
    }

    .col-form-label {
        padding-top: 11px;
    }

    .dropdown {
        .btn {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }

        .dropdown-menu {
            border: 0px;
            padding: 20px 10px;
            border-radius: 10px;
            @include box-shadow;

            a {
                font-size: 12px;
                color: $dark-gray-50;
                font-weight: 300;
                line-height: 30px;

                &:focus {
                    color: $dark-gray-50;
                    background-color: white;
                }
            }
        }
    }
}

.modal-footer {
    border-top: none !important;
    padding: 30px;
    padding-top: 20px;

    .btn {
        margin: 0px;
    }
}

/* -------------------------------------
               Media Query
----------------------------------------*/
@media (max-width: 576px) {

    .modal-header,
    .modal-body,
    .modal-footer {
        padding-right: 20px !important;
        padding-left: 20px !important;
    }
}

.modal-slide-in {
    margin: 0 0 0 auto;
    height: 100vh;
    background: none;

    .modal-header {
        border-bottom: 1px solid #eee;
    }

    .modal-content {
        border-radius: 0;
        height: 100%;

        &>* {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    }

    .modal-body {
        overflow: auto;
        min-height: 0;
    }
}