/*-------------------------------------
                Buttons
--------------------------------------*/
@import "./_variables";

.btn {
    padding: 10px 20px;
    font-size: 14px;
    box-shadow: none !important;

    i {
        font-style: normal;
    }

    &.btn-round {
        border-radius: 50px;
    }

    &.btn-block {
        width: 100%;
    }
}

.btn-sm {
    padding: 7px 15px;
    font-size: 12px;

    &.dropdown-toggle-split {
        padding: 9px 14px;
    }
}

.btn-lg {
    padding: 12px 30px;
    font-size: 16px;
}

.dropdown-toggle-split {
    padding: 9px 14px;
}

.btn-primary,
.btn-secondary,
.btn-success,
.btn-warning,
.btn-danger,
.btn-info {
    color: white;

    &:hover {
        color: white;
    }

    &:focus {
        color: white;
    }
}

.btn-outline-primary,
.btn-outline-secondary,
.btn-outline-success,
.btn-outline-warning,
.btn-outline-danger,
.btn-outline-info {
    &:hover {
        color: white;
    }

    &.dropdown-toggle {
        &:focus {
            color: white;
        }
    }
}

button:disabled {
    color: rgba(255, 255, 255, 0.6) !important;
}

.btn-link {
    color: $primary-color;
}
