@import "variables";

p-autocomplete.p-element {
    width: 100%;

    .p-autocomplete.p-component {
        width: inherit;

        .p-autocomplete-input {
            width: inherit;
            font-size: 14px;
            font-weight: 400;
            color: #32373f;
            border: 1px solid #d8d8d8;
            padding: 10px 12px;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;
            &::placeholder {
                color: $dark-gray-50;
                opacity: 1;
            }
        }
        .p-autocomplete-panel.p-component {
            background-color: white;
            border: 1px solid $border-color;
            border-radius: 0 0 4px 4px;

            .p-autocomplete-item {
                padding: 0.5rem 1rem;
                box-sizing: border-box;

                &:hover {
                    background-color: $light-gray;
                }
            }
        }
    }
}
