@import "variables";

h1,
h2,
h3,
h4,
h5,
h6 {
    color: black;
    font-weight: 700 !important;
}

h1 {
    font-size: 40px;
    line-height: 58px;

    &.large {
        font-size: 48px;
        line-height: 66px;
    }
}

h2 {
    font-size: 32px;
    line-height: 44px;
}

h3 {
    font-size: 28px;
    line-height: 39px;
}

h4 {
    font-size: 24px;
    line-height: 33px;
}

h5 {
    font-size: 20px;
    line-height: 28px;
}

h6 {
    font-size: 18px;
    line-height: 25px;
}

p {
    font-size: 14px;
    line-height: 28px;
    color: $dark-gray-75;
    font-weight: 400;

    &.lg {
        font-size: 16px;
        line-height: 32px;
        font-weight: 300;

        a {
            font-size: 16px;
            line-height: 32px;
        }
    }

    &.input-error {
        color: red;
        line-height: 20px;
        margin-top: 4px;
        margin-bottom: 0px;
    }

    &.xl {
        font-size: 18px;
        line-height: 34px;
        font-weight: 300;
    }

    &.xxl {
        font-size: 22px;
        line-height: 38px;
        font-weight: 300;
    }

    b {
        display: inline-block;
    }
}

label {
    font-size: 14px;
    line-height: 28px;
    color: $dark-gray-75;
    font-weight: 400;

    b {
        display: inline-block;
    }
}

a {
    color: $light-blue;
    font-size: 14px;
    line-height: 28px;
    font-weight: 400;
    text-decoration: none;
    cursor: pointer;
    @include transition;

    &:hover {
        color: $light-blue-75;
        text-decoration: none;
    }

    &.link-gray {
        color: $dark-gray-75;

        &:hover {
            color: $dark-gray;
        }
    }

    &.link-here {
        color: $light-blue;

        &:hover {
            color: $light-blue-75;
        }
    }

    &.sm {
        font-size: 12px;
    }

    b {
        display: inline-block;
    }
}

small {
    font-size: 12px;
    color: $dark-gray-75;
    font-weight: 400;
    display: block;

    a {
        font-size: 12px;
    }

    b {
        font-size: 12px !important;
        display: inline-block;
    }
}

b {
    font-weight: 700;
    display: block;
}

strong {
    font-weight: 700;
    font-size: 14px;
}

ul li {
    font-size: 14px;
    line-height: 28px;
    color: $dark-gray-75;
    font-weight: 400;

    &.sm {
        font-size: 12px;
        line-height: 20px;
    }

    b {
        display: inline-block;
    }
}

ol li {
    font-size: 14px;
    line-height: 28px;
    color: $dark-gray-75;
    font-weight: 400;

    ol {
        margin-bottom: 14px;
    }

    b {
        font-size: 14px;
        display: inline-block;
    }
}

/* -------------------------------------
               Media Query
----------------------------------------*/
@media (max-width: 992px) {
    h1 {
        font-size: 36px;
        line-height: 50px;

        &.large {
            font-size: 40px;
            line-height: 54px;
        }
    }

    h2 {
        font-size: 28px;
        line-height: 40px;
    }

    h3 {
        font-size: 24px;
        line-height: 33px;
    }

    h4 {
        font-size: 20px;
        line-height: 30px;
    }

    p {
        &.lg {
            font-size: 14px;
            line-height: 28px;
        }
    }
}

.body-text {
    font-size: $base-font-size;
}

.small-text {
    font-size: $small-font-size;
}
