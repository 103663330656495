// THIS FILE IS DEPRECATED
// DO NOT ADD ANY NEW STYLES

:root, :before, :after {
    --primaryColor: #eb1d24;
    --primaryColorRGB: 235, 29, 36;
    --bs-success-rgb: 35, 181, 89;
}

*:focus {
outline: none !important;
    box-shadow: none !important;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Open Sans', sans-serif !important;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid $dark-gray-50;
    margin: 1em 0;
    padding: 0;
}

button[disabled] {
    cursor: default;
}

table {
    font-size: 14px;

    th {
        font-size: 12px;
        color: $dark-gray-50;
        border-bottom: 2px solid $dark-gray-10 !important;
    }

    td {
        color: $dark-gray-75;
        border-color: $dark-gray-10;
        font-size: 14px;

        b {
            font-size: 14px;
            display: inline-block;
        }

        a {
            font-size: 14px;
            line-height: 20px;

            &.sm {
                font-size: 12px;
            }
        }

        .bg-danger {
            background-color: $primary-color-10 !important;
            color: $primary-color;
        }

        .bg-success {
            background-color: $light-green-10 !important;
            color: $light-green;
        }

        .bg-warning {
            background-color: $orange-10 !important;
            color: $orange;
        }

        .bg-info {
            background-color: $light-blue-10 !important;
            color: $light-blue;
        }

        .bg-muted {
            background-color: $dark-gray-10 !important;
            color: $dark-gray-75;
        }

    }
}

.badge {
    padding: 6px 15px;
    border-radius: 30px;
    font-size: 12px;
    font-weight: 600;
    width: fit-content;

    &.bg-danger-v2 {
        background-color: $primary-color-10 !important;
        color: $primary-color;
    }

    &.bg-success-v2 {
        background-color: $light-green-10 !important;
        color: $light-green;
    }

    &.bg-warning-v2 {
        background-color: $orange-10 !important;
        color: $orange;
    }

    &.bg-info-v2 {
        background-color: $light-blue-10 !important;
        color: $light-blue;
    }

    &.bg-muted-v2 {
        background-color: $dark-gray-10 !important;
        color: $dark-gray-75;
    }
}

.content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.content-body {
    background-color: white;
    border-radius: 10px;
    padding: 40px;
    margin-top: 20px;

    &.w-sm {
        max-width: 1000px;
        margin: auto;
        margin-top: 20px;
    }
}

.disable-div,
.disable-div-faded,
.processing {
    pointer-events: none !important;

    input,
    textarea,
    button {
        pointer-events: none !important;
    }
}

.grecaptcha-badge {
    visibility: hidden;
}

.number-increment {
    background-color: $light-green-10;
    border-radius: 50px;
    padding: 8px;
    display: flex;
    width: fit-content;
    margin: auto;

    .btn {
        width: 40px;
        height: 40px;
        padding: 0px;

        .bi {
            font-size: 24px;
        }
    }

    .form-control {
        &.is-invalid {
            border-color: $primary-color !important;
        }

        width: 50px;
        border: none;
        background-color: transparent;
        text-align: center;
    }
}

.nav-tabs {
    a {
        color: $dark-gray-75 !important;
        border: none !important;
        border-bottom: 3px solid transparent !important;
        font-size: 12px !important;
        padding: 10px;
        line-height: 14px;
        display: flex;
        align-items: center;

        &.active {
            background-color: transparent !important;
            color: $light-blue !important;
            border-bottom: 3px solid $light-blue !important;
        }

        &:hover {
            color: $light-blue !important;
        }

        .bi {
            font-size: 16px;
            margin-right: 10px;
        }
    }
}

.breadcrumb {
    background-color: transparent;
    padding: 0px;

    .breadcrumb-item {
        font-size: 26px;
        line-height: 26px;
        font-weight: 700;
        color: black;
        padding-right: 6px;
        padding-left: 0px;

        a {
            color: black;
            font-size: 26px;
            line-height: 26px;
            font-weight: 700;
            padding-right: 6px;
            cursor: pointer;

            &:hover {
                opacity: .7;
            }
        }

        &.active {
            color: $dark-gray-50;
        }

        .bi-chevron-right {
            font-size: 20px !important;
            line-height: 26px !important;
            color: $dark-gray-50 !important;
        }

        .bi {
            font-size: 26px;
            color: black;
        }

        &::before {
            display: none;
        }
    }
}

.loader-wrapper {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
}

.bi {

    &.bi-sm,
    &.bi-md,
    &.bi-lg,
    &.bi-xl {
        color: $dark-gray-75;
    }

    &.bi-xsm {
        font-size: 8px;
    }

    &.bi-sm {
        font-size: 16px;
    }

    &.bi-md {
        font-size: 18px;
    }

    &.bi-lg {
        font-size: 20px;
    }

    &.bi-xl {
        font-size: 24px;
    }
}


/*-------------------------------------
                 IMAGE
--------------------------------------*/

.image-wrapper {
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
    padding: 50px 15px;

    img {
        width: 50%;

        &.lg {
            width: 70%;
        }
    }

    p {
        margin-top: 10px;
        margin-bottom: 0px;
    }

    h6 {
        margin-top: 20px;
    }
}

/*-------------------------------------
            Dropdown Menu
--------------------------------------*/

.dropdown-menu {
    border: 0px;
    padding: 20px 10px;
    border-radius: 10px;
    @include box-shadow2;

    .dropdown-item {
        font-size: 12px;
        color: $dark-gray-75;
        font-weight: 400;
        line-height: 30px;
        display: flex;
        align-items: center;
        cursor: pointer;

        b,
        small {
            color: $dark-gray-75;
        }

        i {
            font-size: 16px;
            margin-right: 10px;
        }

        .svg-icon {
            width: 16px;
            height: 16px;
            margin-right: 10px;
            fill: $dark-gray-75;
        }

        &.delete-item {
            color: $primary-color !important;
        }

        &.active {
            background-color: $light-blue;
            color: white;

            b {
                color: white;
            }

            small {
                color: white;
                line-height: 10px;
            }

            &:hover {
                color: white;
            }
        }

        &.disabled {
            color: $dark-gray-25;
            cursor: default;
        }
    }

    .dropdown-item:hover,
    .dropdown-item:focus {
        color: $dark-gray-75;
        text-decoration: none;
        background-color: #f8f9fa;
        outline: none;
        box-shadow: none;
    }

    .dropdown-item.active,
    .dropdown-item:active {
        color: white;
        text-decoration: none;
        background-color: #f8f9fa;
    }

    .dropdown-divider {
        border-color: $dark-gray-10;
    }
}


/*-------------------------------------
            Custom Nav Pills
--------------------------------------*/

.custom-nav {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 6px;
    border-bottom: 2px solid $dark-gray-10;

    a {
        color: $dark-gray-75 !important;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        border-radius: 3px;
        padding: 8px 10px;
        margin-bottom: 5px;
        @include transition;

        .bi {
            font-size: 16px;
            color: $dark-gray-75;
            margin-right: 10px;
            @include transition;
        }

        &:hover:not(.disabled) {
            color: $primary-color !important;

            .bi {
                color: $primary-color;
            }
        }

        &.active {
            color: $primary-color !important;
            background-color: $primary-color-10;

            .bi {
                color: $primary-color;
            }
        }

        &.disabled {
            color: gray;
            cursor: default;
        }
    }
}

.mouse-pointer {
    cursor: pointer;
}

.modal-content-min-height {
    min-height: 750px;
}

.modal-content-min-height-v2 {
    min-height: 450px;
}

.modal-content-min-height-v3 {
    min-height: 550px;
}


/*-------------------------------------
             NOT FOUND
--------------------------------------*/

.not-found-wrapper {
    background-color: $primary-color-5;
    min-height: 100vh;

    .not-found-contents {
        max-width: 700px;
        margin: 0 auto;
        text-align: center;
        padding: 80px 15px;

        img {
            width: 70%;
        }

        h5 {
            margin-top: 20px;
        }
    }
}


/*-------------------------------------
          TOASTER NOTIFICATION
--------------------------------------*/

.toast-container {

    .toast-success {
        color: $light-green !important;
        background-color: $light-green-10;
        background-image: url('../assets/check.png') !important;
    }

    .toast-error {
        color: $primary-color !important;
        background-color: $primary-color-10 !important;
        background-image: url('../assets/error.png') !important;
    }

    .ngx-toastr {
        box-shadow: none !important;
        padding: 15px 10px 15px 50px !important;
        border-radius: 6px !important;

        :hover {
            box-shadow: none !important;
        }
    }
}


/*-------------------------------------
             BS DATEPICKER
--------------------------------------*/

.date-wrapper,
.search-wrapper-2 {
    position: relative;

    .bi {
        font-size: 16px;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        color: $dark-gray-50;
        cursor: pointer;
    }
}

.date-wrapper-2 {
    position: relative;

    input {
        border: 0px;
        font-size: 12px;
        padding-left: 30px;
        color: $dark-gray-75;
    }

    .bi {
        font-size: 16px;
        position: absolute;
        top: 50%;
        left: 4px;
        transform: translateY(-50%);
        color: $dark-gray-75;
        cursor: pointer;
    }
}

.bs-datepicker {
    padding: 4px;
    border-radius: 10px;
    @include box-shadow2;
}

.bs-datepicker-head {
    background-color: $primary-color !important;
}

.theme-green .bs-datepicker-body table td.week span {
    color: $primary-color;
}

.theme-green .bs-datepicker-body table td span.selected,
.theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*="select-"]:after,
.theme-green .bs-datepicker-body table td[class*="select-"] span:after {
    background-color: $primary-color;
}

.bs-datepicker-body {
    border: none;
    z-index: 8000 !important;
}

.bs-datepicker-head button.next span,
.bs-datepicker-head button.previous span {
    margin-top: -2px;
}

.check-wrapper {
    border: 2px solid $dark-gray-10;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;

    .p-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        justify-items: center;

        .toggleWrapper {
            padding-top: 3px;
        }
    }
}

/*-------------------------------------
             SIM BOX
--------------------------------------*/

.sim-box {
    background-color: white;
    border: 2px solid $dark-gray-10;
    border-radius: 10px;
    padding: 20px;
    cursor: pointer;
    @include transition;

    &.active {
        border-color: $light-blue;
        background-color: $light-blue-5;

        &:hover {
            border-color: $light-blue;
            background-color: $light-blue-5;
        }
    }

    &:hover {
        border-color: $dark-gray-25;
    }

    .sim-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        img {
            width: 35px;
        }
    }

    .info b {
        color: $dark-gray-75;
    }
}

/*-------------------------------------
                FILTER
--------------------------------------*/

.filter-wrapper {
    background-color: $light-gray;
    border: 2px solid $dark-gray-10;
    padding: 20px;
    border-radius: 10px;
}


/*-------------------------------------
              SIM QUANTITY
--------------------------------------*/

.sim-quantity {
    border: 2px solid $dark-gray-10;
    border-radius: 10px;
    padding: 20px;
}


/*-------------------------------------
                 FILE
--------------------------------------*/

.file-wrapper {
    border: 2px solid $dark-gray-10;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .file-body {
        display: flex;
        align-items: center;
        padding-right: 15px;
    }

    .file-name {
        display: flex;
        flex-direction: column;
    }

    b {
        font-size: 14px;
        color: $dark-gray;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        word-break: break-word;

        &.sm {
            font-size: 13px;
        }
    }

    small {
        color: $dark-gray-50;
    }

    .file-close {
        display: flex;
        align-items: center;

        .bi-download {
            font-size: 16px;
        }

        .bi-x {
            font-size: 24px;
            color: $dark-gray-25;
            cursor: pointer;
            @include transition;

            &:hover {
                color: $dark-gray-50;
            }
        }
    }
}


/*-------------------------------------
             PRODUCT PRICE
--------------------------------------*/

.product-price {
    border: 2px solid $dark-gray-10;
    border-radius: 10px;
    padding: 15px;
    position: relative;
    cursor: pointer;
    @include transition;

    &:hover {
        border-color: $dark-gray-25;
    }

    &.active {
        border-color: $light-green;
        background-color: $light-green-5;

        .check-icon {
            display: block;
        }
    }

    .check-icon {
        width: 25px;
        height: 25px;
        position: absolute;
        right: 10px;
        top: 10px;
        display: none;
    }
}


/*-------------------------------------
             PAGINATION
--------------------------------------*/

.pagination {
    .page-item {
        .page-link {
            color: $dark-gray-75;
            border: 1px solid $dark-gray-10;
            border-radius: 8px;
            font-size: 12px;
            padding: 4px 10px;
            margin: 0px 2px;

            &:hover {
                background-color: $dark-gray-10;
                color: $dark-gray;
            }
        }

        &.active {
            .page-link {
                background-color: $primary-color;
                color: white !important;
                border-color: $primary-color;
            }
        }

        &.disabled {
            .page-link {
                color: $dark-gray-25;
            }
        }
    }
}


.badge-main-wrapper {
    max-width: 200px;
    display: flex;
    flex-wrap: wrap;

    .badge {
        .bi-three-dots {
            opacity: .3;
        }

        &:hover {
            .bi-three-dots {
                opacity: 1;
                @include transition;
            }
        }
    }
}

.add-tags-dropdown {
    min-width: 300px;
}


/*-------------------------------------
             SCROLLBAR
--------------------------------------*/

/* width */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: $dark-gray-10;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $dark-gray-25;
    border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $dark-gray-50;
    @include transition
}

.form-wrapper::-webkit-scrollbar {
    display: none;
}



.disable-div {
    opacity: .6;
}

.fade-div,
.disable-div-faded {
    opacity: .4;
}



.table-responsive-custom {
    &.smaller-td {
        td {
            font-size: smaller;
        }
    }
}

table.table-sortable {
    thead {
        tr {
            $sort_normal_color: '%23A0A1A5';

            [aria-sort] {
                padding-right: calc(0.75rem + 0.65rem);
                background-image: none;
                background-repeat: no-repeat;
                background-size: 0.65rem 1rem;
                cursor: pointer;
                background-position: right calc(0.75rem / 2) center;
            }

            [aria-sort="none"] {
                background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{$sort_normal_color}' class='bi bi-arrow-down-up' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z'/></svg>");
            }

            [aria-sort="asc"] {
                background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-arrow-down' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z'/></svg>");
            }

            [aria-sort="desc"] {
                background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-arrow-up' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z'/></svg>");
            }
        }
    }
}



/* -------------------------------------
               Media Query
----------------------------------------*/
@media (max-width: 1400px) {
    .table-responsive-custom-2 {
        overflow-x: auto;
    }
}

/* -------------------------------------
               Media Query
----------------------------------------*/
@media (max-width: 1200px) {
    .table-responsive-custom {
        overflow-x: auto;
    }
}

/* -------------------------------------
               Media Query
----------------------------------------*/
@media (max-width: 768px) {
    .pagination {
        justify-content: center !important;
        margin-top: 20px;
    }
}

/* -------------------------------------
               Media Query
----------------------------------------*/
@media (max-width: 576px) {
    .content-body {
        padding: 30px 20px;
    }

    .breadcrumb {

        .breadcrumb-item {
            font-size: 18px;
            line-height: 18px;

            a {
                font-size: 18px;
                line-height: 18px;
            }

            .bi-chevron-right {
                font-size: 16px !important;
                line-height: 18px !important;
            }

            .bi {
                font-size: 18px;
            }
        }
    }
}
